.swiper-slide {
    width: auto;
}

.swiper-container {
    z-index: 0;
    position: relative;
}

.slick-prev {
    background-color: black !important;
    border-radius: 50%;
    padding: 1.75px 0px;
}
.slick-next {
    background-color: black !important;
    border-radius: 50%;
    padding: 1.75px 0px;
}


